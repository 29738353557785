.modal {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border-radius: 1rem;
    overflow: hidden;
}

.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background-color: white;
    box-shadow: 24;
    padding: 20px;
    color: black;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.modal-time {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.8rem;
    align-self: end;
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 5%;
}

.modal-check {
    color: green;
    width: 4.2rem;
    height: 4.2rem;
    font-size: 4rem;
    align-self: center;
    margin: 1rem;
}

/* mobile css */
@media only screen and (max-width: 768px) {
    .modal-box {
        width: 80%;
    }
}