.footer-container{
  width: 100%;
  margin-top: 200px;
}

.footer-stacks-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 16px;
  margin-bottom: 40px;
}

.footer-stack{
  display: flex;
  flex-direction: column;

}

.footer-stack a{
  padding: 4px 0px;
}

.footer-copyright-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.footer-social-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  width: 10%;
  padding: 8px;
}
.footer-social-container a{
  display: flex;
  justify-content: center;
  align-items: center;

}

/* Mobile WebApp */
@media only screen and (max-width: 768px) {
  .footer-stacks-container{
    flex-direction: column;
    margin: 0px;
  }
  .footer-stack{
    align-items: center;
    margin-bottom: 30px;
  }
  .footer-container{
    margin-top: 50px;
  }
  .footer-social-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer-social-container a{
    margin: 0px 20px;
  }
  
}