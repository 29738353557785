.quote-container {
  display: flex;
  flex: 3;
  justify-content: space-around;
  flex-direction: row;
  /* padding: 120px 40px; */
  /* background: url(./../../../assets/quote-section-background.png); */
  background-image: url(./../../../assets/background-cut.jpeg);
  background-size: cover;
}

.quote-container-inner {
  display: flex;
  flex: 3;
  justify-content: space-around;
  flex-direction: row;
  padding: 120px 40px;
}



.text-container {
  width: 50%;
  padding: 20px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.button-container {
  display: flex;
  justify-content: space-around;
  /* width: 50%; */
}

.button-container button {
  border-radius: 25px;
  white-space: nowrap;
}

.button-container button:not(:last-child) {
  margin-right: 0.5em;
}

.quote-caption {
  padding: 20px 0px;
  z-index: 1;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-container {
  width: 30%;
  padding: 0px 10px;
  background-color: rgba(36, 38, 41, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.form-container h5 {
  padding: 25px 0px;
}

.form-submit-button {
  border-radius: 15px !important;
  width: 90%;
  margin: 20px !important;
}

.form-field {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  margin: 8px;
}

.form {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.oneLiner {
  grid-column: 1/3;
}

.name-form {
  display: flex;

}

.form-error {
  color: red !important;
  text-align: center;
}


.text-btn {
  font-size: 1rem !important;

}

.text-btn-primary {
  background-color: #FB4D63;
  color: white;
}

.text-btn-dark {
  background-color: rgba(36, 38, 41, 1);
  color: white;
}

@media only screen and (max-width: 768px) {

  /* For Mobile screens */

  .quote-container-inner {
    display: flex;
    flex: 3;
    justify-content: space-around;
    flex-direction: column;
    padding: 20px 10px;
    position: relative;
  }

  .form-container h5 {
    padding: 25px 20px;
    text-align: center;
  }

  .quote-container {
    flex-direction: column;
    padding: 20px;
    position: relative;
  }


  .text-container::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .text-container {
    margin: 0px;
    padding: 0px;
    width: 100%;
    align-items: center;
  }

  .button-container {
    width: 100%;
  }

  .quote-caption {
    text-align: center;
    z-index: 1;
    color: white !important;
  }

  .quote-title h1 {
    font-size: 1.7rem;
    text-align: center;
    z-index: 1;
  }

  .form-container {
    width: 100%;
    padding: 0px;
    margin-top: 40px;
    margin-bottom: 10px;
  }

}

@media only screen and (max-width: 358px) {
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button-container button:not(:last-child) {
    margin-bottom: 1em;
  }
}