html {
  overflow-x: hidden;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #18191b;
  overflow-x: hidden;
  background-attachment: scroll;
}

::-webkit-scrollbar{
  display: none;
}

@font-face {
  font-family: 'Druk-Wide-Bold';
  src: local('Druk-Wide-Bold'),
    url('./fonts/Druk-Wide-Bold/Druk-Wide-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Manrope-Regular';
  src: local('Manrope-Regular'),
    url('./fonts/Manrope/Manrope-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Manrope-Medium';
  src: local('Manrope-Medium'),
    url('./fonts/Manrope/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Manrope-Bold';
  src: local('Manrope-Bold'),
    url('./fonts/Manrope/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Manrope-ExtraBold';
  src: local('Manrope-ExtraBold'),
    url('./fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
