.navbar-links-container {
  display: none;
}

.navbar-links {
  padding: 5px 12px ;
  margin: 0px 2px;
}
.navbar-links:hover {
  text-shadow: 0px 0px 10px #49d6d2;
}
.navbar-contact-button{
  display: none !important;
  border-radius: 20px !important;
  border-width: 2px !important;
}
.navbar-contact-button:hover{
  border-width: 2px !important;
}
.show-menu-button {
  color: #fff !important; 
}

@media screen and (min-width: 768px) {
  /* bigger than Screens */
  .show-menu-button {
    color: #fff !important;
    display: none !important;
  }

  .navbar-links-container {
    display: flex !important;
    justify-content: space-between !important;
  }
  .navbar-contact-button{
    display: block !important;
  }
  .mobile-menu-drawer{
    display: none;
  }
  
}
