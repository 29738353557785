.layout {
  display: flex;
  flex-direction: column;
  /* background: url(./../../assets/background.jpeg); */
  background-size: cover;
  margin-top: 64px;
}

/* Trusted section starts */
.trusted-section-container {
  background-color: rgba(36, 38, 41, 1);
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
}

.trusted-section-title {
  color: #49D6D2;
  padding: 12px;
  text-align: center;

}

.mobile {
  display: none;
}

.trusted-section-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  align-items: center;
}

.trusted-section-icons img {
  margin: 10px 20px;
}


/* Trusted section ends */

/* Consulting section start */
.consulting-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 16px;
}

.consulting-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.consulting-title-body {
  text-align: center;
  padding: 0px 150px
}

.consulting-title-title {
  padding-bottom: 20px;
  text-align: center;
}

.consulting-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
  /* grid-template-rows: 1fr 1fr; */
  margin: 60px 16px;
  /* gap: 5px; */
  background-color: rgba(36, 38, 41, 1);
  border-radius: 20px;
}

.consulting-grid-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10rem;
  /* background-color: rgba(36, 38, 41, 1); */
  padding: 45px;
  text-align: center;
}

.tl {
  border-radius: 20px 0px 0px 0px;
}

.dtr {
  border-radius: 0px 20px 0px 0px;
}

.dbl {
  border-radius: 0px 0px 0px 20px;
}

.br {
  border-radius: 0px 0px 20px 0px;
}

/* Consulting section ends */


/* delivery section start */

.delivery-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delivery-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.delivery-title-body {
  text-align: center;
  padding: 0px 160px
}

.delivery-title-title {
  padding-bottom: 20px;
  text-align: center;
}


.delivery-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 60px 16px;
  width: 90%;
  gap: 8px;
}

.delivery-grid-item {
  padding: 16px;
}

.delivery-grid-item p {
  margin-left: 24px;

}

.delivery-grid-item h6 {
  margin-left: 24px;
  margin-bottom: 20px;
}

/* Delivery section ends */

/* Technologies section starts */

.technology-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(36, 38, 41, 1);
  width: 80%;
  align-self: center;
  padding: 40px;
  border-radius: 16px;
}

.technology-container h2 {
  padding: 20px;
  text-align: center;
}

.technology-grid-container {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  padding: 40px;
  /* column-gap: 30px; */
  /* row-gap: 20px; */
}

.technology-grid-item {
  display: flex;
  align-items: center;
  padding: 8px 24px;
}

.technology-grid-item img {
  margin-right: 16px;
}

/* Technologies section ends */

/* services section starts */
.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 10px;
}

.services-container-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 80px;
}

.services-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 60px 16px;
  width: 90%;
  gap: 8px;
}

.services-grid-item {
  padding: 16px;
}

.services-grid-item p {
  margin-left: 24px;

}

.services-grid-item h6 {
  margin-left: 24px;
  margin-bottom: 8px;
}

/* services section ends */


/* Schedulecall section starts */

.schedule-call-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(36, 38, 41, 1);
  width: 80%;
  align-self: center;
  padding: 40px;
  border-radius: 16px;
}

.schedule-call-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.schedule-call-title h3 {
  margin-bottom: 20px;
}

.schedule-form-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 30px 16px;
  width: 80%;
  row-gap: 0px;
  column-gap: 8px;
  width: 100%;
}

.form-grid-item1 {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
}

.form-grid-error {
  grid-column-start: 1;
  grid-column-end: 4;
  /* grid-row-start: 1; */
  grid-row-end: 1;
}

.form-grid-item2 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
}

.schedule-form-submit {
  border-radius: 25px !important;
  margin: 20px !important;
  height: 60px !important;
}

.schedule-form-checkbox span {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 200;
}

@media only screen and (max-width: 1014px) {
  .technology-grid-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Schedulecall section ends */

/* Mobile WebApp */
@media only screen and (max-width: 768px) {
  .layout {
    background: url(./../../assets/mobileBackground.jpeg);
    background-size: cover;
  }

  /* Trusted Section starts */
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .trusted-section-icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    justify-items: center;
  }

  .trusted-section-icons img {
    height: 20px;
  }

  /* Trusted Section ends */

  /* Consulting section starts */
  .consulting-section-container {
    margin: 30px 10px;
    padding: 0px;
  }

  .consulting-title-body {
    padding: 0px;
  }

  .consulting-title-container {
    padding: 20px;
  }

  .consulting-grid-container {
    grid-template-columns: 1fr 1fr;
    margin: 40px 16px;
  }

  .consulting-grid-card {
    padding: 10px;
    width: 7rem;
  }

  .consulting-grid-card h6 {
    font-size: 1rem;
    font-weight: 300;
  }

  .mbl {
    border-radius: 0px 0px 0px 20px;
  }

  .mtr {
    border-radius: 0px 20px 0px 0px;
  }

  .dbl {
    border-radius: 0px;
  }

  .dtr {
    border-radius: 0px;
  }

  /* Consulting section ends */

  /* delivery section starts */
  .delivery-section-container {
    margin: 40px 15px !important;
    padding: 0px !important;

  }

  .delivery-title-container {
    padding: 0px;
  }

  .delivery-title-title {
    margin: 20px !important;
  }

  .delivery-title-body {
    padding: 0px;
  }

  .delivery-grid-container {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 40px 16px;
  }

  .delivery-grid-item {
    padding: 0px;
    margin: 0px;
    text-align: center;
  }

  .delivery-grid-item p {
    margin: 0px;
  }

  .delivery-grid-item h6 {
    margin: 0px;
  }

  /* delivery section ends */

  /* Technologies section starts */
  .technology-container {
    text-align: center;
    padding: 20px 20px;
  }

  .technology-container h2 {
    padding: 0px;
    font-size: 1.5rem;

  }

  .technology-grid-container {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px 0px;
  }

  .technology-grid-item {
    padding: 5px;
  }

  .technology-grid-item img {
    height: 30px;
    margin-right: 10px;

  }

  .technology-grid-item p {
    font-size: 0.7rem;
  }

  /* Technologies section ends */

  /* Services Section starts */
  .services-container {
    padding: 0px;
    margin: 40px 10px;
  }

  .services-container-title {
    text-align: center;
    padding: 0px;
  }

  .services-grid-container {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 40px 16px;
  }

  .services-grid-item p {
    margin: 0px;
  }

  .services-grid-item h6 {
    margin: 0px;
  }

  /* Services Section ends */

  /* Schedule call section starts */
  .schedule-call-container {
    text-align: center;
    padding: 20px;
  }

  .schedule-call-title h3 {
    font-size: 1.5rem;
  }

  .MuiFormControlLabel-label {
    text-align: left;
  }

  .schedule-form-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .schedule-form-submit h6 {
    font-size: 1rem;
  }

  .schedule-call-title {
    padding: 20px 0px;
  }

  /* Schedule call section ends */

}